import {
  FetchArgs,
  createApi,
  BaseQueryFn,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'
import { AnyAction } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'
import { CreateApiOptions } from '@reduxjs/toolkit/dist/query/createApi'
import { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery'

import cognito from './cognito'
import { debounce } from 'lodash'
import { Project } from './project'
import { getStrings } from './strings'
import { REHYDRATE } from 'redux-persist'
import { getStore, StoreStateType } from './store'
import { Constants } from './constants'

export function extractRehydrationInfo(
  action: AnyAction,
  {
    reducerPath,
  }: {
    reducerPath: string
  },
) {
  if (action.type === HYDRATE) {
    return action.payload[reducerPath]
  }
}

let q: BaseQueryFn<any> | null = null

const debouncedLogout = debounce(
  () => {
    getStore().dispatch(service.endpoints.logout.initiate({}, {}))
    // logout(getStore(), {})
  },
  500,
  { leading: true },
)
export const baseApiOptions = (queryArgs?: Partial<FetchBaseQueryArgs>) => {
  const baseQuery: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
  > = async (args, api, extraOptions) => {
    const _baseQuery = q
      ? q
      : fetchBaseQuery({
          baseUrl: Project.api,
          fetchFn: async (args) => {
            const result = await fetch(args)
            const isUnauthorised = result?.status === 401
            if (isUnauthorised) {
              debouncedLogout()
            }

            return result
          },
          prepareHeaders: async (headers, { endpoint, getState }) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const state = getState() as StoreStateType
            Object.entries(Project.headers).forEach(([key, value]) => {
              headers.set(key, value.toString())
            })
            if (
              endpoint !== 'register' &&
              endpoint !== 'createConfirmEmail' &&
              endpoint !== 'createResetPassword' &&
              endpoint !== 'createResendConfirmationEmail' &&
              endpoint !== 'verifyAccount' &&
              endpoint !== 'createForgotPassword'
            ) {
              try {
                const session = await cognito.getSession()
                const jwtToken = await session!.getAccessToken()
                const testData = state?.devSettings?.testData
                if (testData) {
                  headers.set('x-is-test-request', `True`)
                }
                if (jwtToken?.getJwtToken()) {
                  headers.set(
                    'Authorization',
                    `Bearer ${jwtToken?.getJwtToken()}`,
                  )
                }
              } catch (e) {}
            }

            headers.set('Accept-Language', getStrings().getLanguage())
            if (Constants.E2E) {
              headers.set('E2E-Test', '1')
            }
            return headers
          },
          ...queryArgs,
        })
    q = _baseQuery
    return _baseQuery(args, api, extraOptions)
  }

  const res: Pick<
    CreateApiOptions<any, any, any, any>,
    | 'baseQuery'
    | 'keepUnusedDataFor'
    | 'refetchOnReconnect'
    | 'refetchOnFocus'
    | 'extractRehydrationInfo'
  > = {
    baseQuery,
    extractRehydrationInfo: (action, { reducerPath }) => {
      if (action.type === HYDRATE) {
        return action.payload[reducerPath]
      }
    },

    refetchOnFocus: true,
    refetchOnReconnect: true,
  }
  return res
}

const transformQuery = (query: any | undefined | null) => {
  // @ts-ignore
  if (!query?.data) {
    // if the query had data, treat it as cache
    return null
  }
  return {
    ...query,
    fulfilledTimeStamp: null,
    status: 'fulfilled',
  }
}
export const service = createApi({
  ...baseApiOptions(),
  endpoints: () => ({}),
  // @ts-ignore
  extractRehydrationInfo(action) {
    if (
      (action.type === REHYDRATE ||
        action.type === '__NEXT_REDUX_WRAPPER_HYDRATE__') &&
      action.payload?.service
    ) {
      const queries = {}
      Object.keys(action.payload?.service?.queries).map((key) => {
        // @ts-ignore
        queries[key] = transformQuery(action.payload?.service?.queries[key])
      })
      return {
        'config': {},
        'mutations': {},
        'provided': {},
        'queries': queries,
        'subscriptions': {},
      }
    }
  },
  keepUnusedDataFor: 10,
  reducerPath: 'service',
})
