import { FC } from 'react'
import { Constants } from 'common/constants'

type LogoType = {}

export const LogoSVG = ({ height = 40, width = 40 }) => (
  <svg
    className='text-secondary'
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 40 40'
    fill='none'
  >
    <path d='M0 20C0 8.954 8.954 0 20 0s20 8.954 20 20-8.954 20-20 20S0 31.046 0 20Zm20 16.748c9.235 0 16.748-7.513 16.748-16.748 0-9.235-7.513-16.748-16.748-16.748-9.235 0-16.748 7.513-16.748 16.748a16.662 16.662 0 0 0 3.314 9.99 210.648 210.648 0 0 0 4.664-4.421v-.003C5.095 18.425 7.66 13.327 12.396 8.67l3.338 3.36 3.453-3.627a2.676 2.676 0 1 1 3.876 3.69l-3.557 3.737 2.114 2.128 2.317 2.333 3.451-3.627a2.676 2.676 0 1 1 3.877 3.69l-3.556 3.736 3.135 3.157C26.23 31.873 21.2 34.38 14.28 28.573l-.007.01a192.347 192.347 0 0 1-4.737 4.485A16.667 16.667 0 0 0 20 36.748Z'></path>
  </svg>
)
const Logo: FC<LogoType> = ({}) => {
  return (
    <div className='d-flex align-items-center gap-4'>
      <LogoSVG height={40} width={40} />
      <span className='logo d-none d-md-block fw-bold'>
        {Constants.projectName}
      </span>
    </div>
  )
}

export default Logo
